import React from 'react'
import './Certificate.css'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import CertificateCard from '../certificatesCard/CertificateCard'
import certificatesData from '../../data/certificates.json'

const Certificate = () => {
  return (
    <Section title="Certifications">
      <div className="certificate-content">
        <ul className="certificate-list">
          {certificatesData.certificates.reverse().map((certificate) => (
            <li key={`certificates-${certificate.company}`}>
              <Fade bottom duration={1000} distance="20px">
                <CertificateCard certificate={certificate} />
              </Fade>
            </li>
          ))}
        </ul>
        <Fade bottom duration={1200} distance="20px">
        </Fade>
      </div>
    </Section>
  )
}

export default Certificate
