import React from 'react'
import './CertificateCard.css'

const CertificateCard = ({ certificate }) => {
  let { link, company, title, dateFrom, dateExpiration, info } = certificate
  return (
    <a
      className="certificate-link"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="certificate-card-wrapper">
        <div className="certificate-card">
          <div className="certificate-card-top">
            <div
              className="certificate-bg"
              style={{ background: certificate.colourPrimary }}
            ></div>
            <h2>{company}</h2>
            <div className="image-wrapper">
              <div
                className="certificate-bg logo-bg"
                style={{
                  background: certificate.colourSecondary
                    ? certificate.colourSecondary
                    : certificate.colourPrimary,
                }}
              ></div>
              <img
                className="company-logo"
                src={require(`../../images/badges/${certificate.badge
                  .replace(/ /g, '')
                  .toLowerCase()}.png`)}
                alt={`${company}-logo`}
                style={
                  certificate.logoheight
                    ? {
                      height: `${certificate.logoheight}%`,
                    }
                    : { width: `${certificate.logowidth}%` }
                }
              />
            </div>
          </div>
          <div className="certificate-card-bottom">
            <div>
              <h2>{title}</h2>
              <h3>
                {dateFrom} - {dateExpiration}
              </h3>
              <ul>
                {info.map((point, idx) => (
                  <li key={`${company}-point-${idx}`}>{point}</li>
                ))}
              </ul>
            </div>
            <div className="certificate-card-tech">
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default CertificateCard
